import { CSSProperties } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { getImageUrl } from '@/api/sanity'

import getUrl from '@/helpers/links'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'
const bem = BEMHelper(styles)

interface Props {
  slug: string
  topicTheme: {
    background: string
    promoted: string
  }
  title: string
  image: string | SanityImageSource
  preamble: string
}

export default function CollectionEntry({ title, preamble, slug, topicTheme, image }: Props) {
  const imageURL = getImageUrl(image).width(800).url()
  const href = getUrl('subTopic', slug)

  if (!href) {
    return null
  }

  return (
    <Link
      {...bem('')}
      href={href}
      style={
        {
          '--color-background': topicTheme.background,
          '--color-promoted': topicTheme.promoted,
        } as CSSProperties
      }
    >
      <div {...bem('image-wrapper')}>
        {imageURL && (
          <Image
            {...bem('image')}
            src={imageURL}
            alt={typeof image === 'object' && 'alt' in image && image.alt ? image.alt : ''}
            width={300}
            height={200}
          />
        )}
      </div>

      <div {...bem('content')}>
        <h2 {...bem('title')}>{title}</h2>
        <p {...bem('preamble')}>{preamble}</p>
      </div>
    </Link>
  )
}
