import algoliasearch from 'algoliasearch'

export const indexName =
  process.env.NEXT_PUBLIC_SANITY_DATASET === 'production' ? 'sanity' : 'sanity_develop'

export const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '2VJD1TGA0Y',
  process.env.ALGOLIA_WRITE_TOKEN || '82c2dc8a846a61077f78cc363ff836b6',
)

export const index = client.initIndex(indexName)
