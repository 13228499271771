/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from 'next/router'

import ArticleEntries from '@/components/ArticleEntries'
import DefinitionSearchList from '@/components/DefinitionSearchList'
import FrontpageCover from '@/components/FrontpageCover'
import LearningAidsEntry from '@/components/LearningAidsEntry'
import LessonEntry from '@/components/LessonEntry'
import NavList from '@/components/NavList'
import Search from '@/components/Search'
import SearchFilters from '@/components/SearchFilters'
import Section from '@/components/Section'
import TopicEntry from '@/components/TopicEntry'
import VideoEntries from '@/components/VideoEntries'

import { useFocusSimple as useFocus } from '@/hooks/useFocus'
import useSearch from '@/hooks/useSearch'

import { topicThemeQuery } from '@/api'
import { getClient } from '@/api/sanity'

const supportedTypes = [
  'lesson',
  'article',
  'resource',
  'topic',
  'definition',
  'staticPage',
  'talkingType',
  'quiz',
  'video',
  // 'student',
]

export default function SearchSection({ themes, subjects, topics: allTopics }) {
  const router = useRouter()
  const q = router.query.q?.toString() || ''
  const from = parseInt((router.query.dfrom || '').toString())
  const to = parseInt((router.query.dto || '').toString())

  const { loading, error, data } = useSearch(q, {
    supportedTypes,
    filtersInput: {
      duration: {
        from,
        to,
      },
      subjects: router.query.subjects?.toString(),
      types: router.query.types?.toString(),
      grades: {
        grunnskole: router.query.grades?.toString().includes('grunnskole'),
        vgs: router.query.grades?.toString().includes('vgs'),
      },
      topic: router.query.topics?.toString(),
    },
  })

  const hits = data?.hits || []
  const facets = data?.facets || {}
  // const noResults = Boolean(data?.nbHits === 0 && q && q?.length !== 0)
  const noResults = hits.length === 0 && q && q?.length !== 0
  const lessons = hits.filter(({ type }) => type === 'lesson')
  const articles = hits.filter(({ type }) => type === 'article')
  const resources = hits.filter(({ type }) => type === 'resource')
  const topics = hits.filter(({ type }) => type === 'topic')
  const definitions = hits.filter(({ type }) => type === 'definition')
  const staticPages = hits.filter(({ type }) => type === 'staticPage')
  const talkingTypes = hits.filter(({ type }) => type === 'talkingType')
  const quizTypes = hits.filter(({ type }) => type === 'quiz')
  const videoTypes = hits.filter(({ type }) => type === 'video') // TODO: Fix typing
  // const student = hits.filter(({ type }) => type === 'student')
  const menu: { _key: string; title: string }[] = data?.filteredFacets?.type
    ? [
      ['topic', 'Tema'],
      ['lesson', 'Undervisningsopplegg'],
      // ['student', 'Elevopplegg'],
      ['staticPage', 'Sider'],
      ['article', 'Artikler'],
      ['video', 'Video'],
      ['resource', 'Plakater/arbeidsark'],
      ['talkingType', 'Samtalekort'],
      ['quiz', 'Quiz'],
      ['definition', 'Ordbok'],
    ]
      .filter(
        ([key]) =>
          data.filteredFacets?.type[key] &&
          data.hits &&
          data.hits.filter(({ type }) => type === key).length > 0,
      )
      .map(([key, title]) => ({
        _key: key,
        title: `${title} (${data?.hits?.filter(({ type }) => type === key).length})`,
      }))
    : []

  const active = useFocus(menu.map(({ _key }) => _key))

  const hasSearch = !!data?.filteredFacets?.type || !!noResults

  if (error) {
    console.warn(error)
  }

  return (
    <>
      <NavList
        active={active}
        inline
        items={menu}
        back={{ title: 'Tilbake', href: '/' }}
        hideBack={!hasSearch}
      />

      <FrontpageCover hasSearch={hasSearch}>
        <Search
          key={router.isReady.toString()}
          loading={loading}
          defaultValue={router.query.q?.toString() || ''}
          placeholder="Hva leter du etter?"
          small
        >
          <SearchFilters
            subjects={subjects}
            facets={facets}
            showDurationFilter={false}
            topics={allTopics}
          />
        </Search>
      </FrontpageCover>

      {loading && <h2>Laster...</h2>}
      {noResults && !loading && (
        <div>
          <h2>Ingen resultater</h2>
          <br />
          <p>Søket ditt ga ingen treff. Prøv å justere på søkeordet eller tilvalgene.</p>
        </div>
      )}

      {topics?.length > 0 && (
        <Section title="Tema" preamble={`${topics.length} treff`} id="topic" type="list">
          <TopicEntry
            items={topics.map((topic) => ({
              _id: topic.objectID,
              slug: topic.slug || '',
              title: topic.title || '',
              topicTheme: themes.find(({ _id }) => _id === topic.objectID) || '',
            }))}
          />
        </Section>
      )}

      {lessons?.length > 0 && (
        <Section
          title="Undervisningsopplegg"
          preamble={`${lessons.length} treff`}
          id="lesson"
          type="list"
        >
          <LessonEntry
            items={lessons.map((lesson) => ({
              ...lesson,
              topicTheme: themes.find(({ _id }) => _id === lesson?.topic._id),
            }))}
          />
        </Section>
      )}

      {staticPages?.length > 0 && (
        <Section
          title="Sider fra Tenk."
          preamble={`${staticPages.length} treff`}
          id="staticPage"
          type="list"
        >
          <ArticleEntries
            urlBuilder={(slug) => `/${slug}`}
            articles={
              staticPages.map((item) => ({
                ...item,
                _type: 'staticPage',
                _id: item.objectID,
              })) as []
            }
          />
        </Section>
      )}

      {articles?.length > 0 && (
        <Section
          title="Artikler fra Tenk."
          preamble={`${articles.length} treff`}
          id="article"
          type="list"
        >
          <ArticleEntries
            articles={
              articles.map((item) => ({ ...item, _type: 'article', _id: item.objectID })) as any[]
            }
          />
        </Section>
      )}

      {videoTypes?.length > 0 && (
        <Section title="Video" preamble={`${videoTypes.length} treff`} id="video" type="list">
          <VideoEntries items={videoTypes as any[]} />
        </Section>
      )}

      {resources?.length > 0 && (
        <Section
          title="Plakater/arbeidsark"
          id="resource"
          preamble={`${resources.length} treff`}
          type="list"
        >
          <LearningAidsEntry
            items={resources.map((item) => ({
              ...item,
            }))}
          />
        </Section>
      )}

      {talkingTypes?.length > 0 && (
        <Section
          title="Samtalekort"
          id="talkingType"
          preamble={`${talkingTypes.length} treff`}
          type="list"
        >
          <LearningAidsEntry
            items={talkingTypes.map((item) => ({
              ...item,
            }))}
          />
        </Section>
      )}

      {quizTypes?.length > 0 && (
        <Section title="Quiz" id="quiz" preamble={`${quizTypes.length} treff`} type="list">
          <LearningAidsEntry
            items={quizTypes.map((item) => ({
              ...item,
            }))}
          />
        </Section>
      )}

      {definitions?.length > 0 && (
        <Section
          title="Ordbok"
          preamble={`${definitions.length} treff`}
          id="definition"
          type="list"
        >
          <DefinitionSearchList
            items={definitions.map((definition) => ({ ...definition, _id: definition.objectID }))}
            ungrouped
          />
        </Section>
      )}
    </>
  )
}

export async function getStaticProps({ preview = false }) {
  const client = getClient(preview)
  const themes = await client.fetch(`*[_type == 'topic']${topicThemeQuery}`)
  const subjects = await client.fetch(`*[_type == 'subject']{ _id, "slug": slug.current, title }`)

  return {
    props: {
      themes,
      subjects,
    },
    revalidate: 30,
  }
}
